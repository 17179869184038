.app__blanks {
  overflow: hidden;
}
.app__blanks-title {
  background-color: var(--color-blue-200);
  padding-bottom: 0;
}
.app__blanks-title h3 {
  color: var(--color-golden);
  font-family: var(--font-alt);
  font-size: 24px;
  font-weight: 100;
  text-align: center;
  padding-bottom: 40px;
}

.app__blanks-section {
  display: flex;
  flex-direction: column;
  padding: 20px; 
}
.app__blanks-section__content {
  background-color: #f5efdbc9;
  display: flex;
  flex-direction: column;
  gap: 10px;
  width: 100%; 
  padding:15px;
  border: 2px solid var(--color-grey);
  box-shadow: 0px 0px 10px 0px rgba(2, 23, 41, 0.75); 
  margin-bottom: 50px;
}


.app__blanks-section__content-item{
border-bottom: 1px dotted var(--color-grey);
}
.app__blanks-section__content-item .active{
  background-color: transparent; 
}
.app__blanks-section__content-item__header{
  padding: 0.5rem 3rem 0.5rem 1rem;
  min-height: 3.5rem;
  line-height: 1.25rem;
  font-weight: bold;
  display: flex;
  align-items: center;
  position: relative;
  cursor: pointer;
}
.app__blanks-section__content-item__header h3{
  pointer-events: none;
  font-family: var(--font-alt);
  font-size: 14px;
  color: var(--color-blue-200);
}
.app__blanks-section__content-item__header:hover h3,
.app__blanks-section__content-item__header:hover:after{
  /* color: var(--color-blue-200); */
  color:#52483d;
}
.app__blanks-section__content-item__header::after{
content:"\002B";
font-size:1.8rem;
position: absolute;
right:1rem;
color: var(--color-blue-200);
}
.app__blanks-section__content-item__header.active::after{
  content:"\2212";
  color: var(--color-blue-200);
  }

  .app__blanks-section__content-item__body{
    max-height: 0;
    overflow: hidden;
    transition:max-height 0.3s ease-out;
  }
  .app__blanks-section__content-item__body-text{   
    padding: 1rem;
    line-height: 1.5rem;
    border-top: 3px solid;
    border-image: linear-gradient(to right,transparent,#52483d, transparent)1;
    text-indent: 20px;
  }
  .app__blanks-section__content-item__body-text p{  
    font-family: var(--font-alt);  
    line-height: 26px;
    font-size: 12px;
  }
  
@media screen and (min-width: 650px) {
  .app__blanks-section__content-item__body-text p {
    font-size: 15px;
    line-height: 30px;
  }
  .app__blanks-section__content { 
    width:90%; 
    padding:50px;  
  }
}
@media screen and (min-width: 1150px) {

  .app__blanks-section__content { 
    width:75%; 
    padding:100px;  
  }
  .app__blanks-section__content-item__header h3{   
    font-size: 18px;  
  }
  .app__blanks-section__content-item__body-text p{     
    font-size: 18px;
  }
}
