.app__tariffPage {
    display: flex;
    flex-direction: column;
    gap: 5px;
    padding-top: 110px;
    overflow: hidden;
}
.app__tariffPage .app__tariff-title{  
    padding: 20px;
    background-color: #000000bd;
}
.app__tariffPage-wrapper {  
    width: 100%; 
    display: flex;
    flex-direction: column;
    justify-content: start;
    align-items: center;   
}
.app__tariffPage-button{ 
    font-family: var(--font-alt);
    font-size: 12px;
    /* background-color: var(--color-black);   */
    background-color: #000000bd;
    color:var(--color-golden);  
    border: 1px solid var(--color-golden);   
    width:100%;
    padding: 10px;
    
    margin-bottom: 2px; 
    cursor: pointer;   
    text-align: center;
    opacity: 0.9;
}
.app__tariffPage-button:hover{    
    color:var(--color-golden);
    opacity: 1;  
    box-shadow: inset 2px -1px 17px 1px rgb(163, 163, 168);
}
.app__tariffPage-feeTitle{ 
    font-family: var(--font-alt);
    font-size: 12px;
    font-weight: 900;
    background-color: var(--color-crimson);    
    width:100%;
    padding: 10px;
    color:var(--color-black);
    box-shadow:inset 1px 1px 10px 1px rgba(2, 23, 41, 0.75);
    margin-bottom: 2px;     
    opacity:0.9;
    text-align: center;    
}
.app__tariffPage-nav {
    width: 100%;
    display: none;
    flex-direction: column;
    justify-content: space-between;
    align-items: center;       
 
}

.app__tariffPage-nav__links {
    display: none;
    flex-direction: column;  
    align-items: center;
    justify-content: center;
    width: 100%;
    list-style: none;
    background-color: black;
    opacity: 0.8;
}

.app__tariffPage-nav__links li {
    width: 100%;
    margin: 0;
    cursor: pointer;
    position: relative;
    display: flex;
    align-items: center;
    background-color: transparent;
    box-shadow: -3px 2px 20px 4px rgba(0, 0, 0, 0.75);   
    padding: 5px;
    padding-left: 20px;
}

.app__tariffPage-nav__links li:hover a {
    color: var(--color-golden);
}
.app__tariffPage-nav__links li a {
    width: 100%;
}

.app__tariffPage-nav__smallscreen {
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: center;   
    
    position: absolute;
    top:50px;
    z-index: 1;
}
.app__tariffPage-nav__links-smallscreen {
    display: flex;
    flex-direction: column;  
    align-items: center;
    justify-content: center;
    width: 100%;
    list-style: none;
    background-color: var(--color-black);
}
.app__tariffPage-nav__links-smallscreen li {
    width: 100%;
    margin: 0;
    cursor: pointer;
    position: relative;
    display: flex;
    align-items: center;
    background-color: transparent;   
    box-shadow: -2px 2px 10px 2px rgba(0, 0, 0, 0.75);   
    padding: 8px;
    padding-left: 20px;
    opacity: 0.8;
}

.app__tariffPage-nav__links-smallscreen li:hover a {
    color: var(--color-golden);
}
.app__tariffPage-nav__links-smallscreen li a {
    width: 100%;
}
.active {
    background-color: rgb(49, 71, 71);
}

.app__tariffPage-content {
    display: flex;
    flex-direction: column;
    background-color: #f5efdbc9;
    padding: 30px;
    border: 2px solid var(--color-grey);
    box-shadow: 0px 0px 10px 0px rgba(2, 23, 41, 0.75);
    width: 100%;
    height: max-content;
    min-height: 400px;    
    opacity:0.2;
}
@media screen and (min-width: 350px) {

    .app__tariffPage-content {    
      padding-top: 50px;
      margin-top:10px;
    }   
}

@media screen and (min-width: 450px) {

    .app__tariffPage-content {
        margin-top: 35px; 
    }
    .app__tariffPage-button,
    .app__tariffPage-feeTitle {
        font-size: 14px;
     }
}

@media screen and (min-width: 1024px) { 
    .app__tariffPage .app__tariff-title{
        margin-top: 20px;
        padding: 30px;
    }  
    .app__tariffPage-button,
    .app__tariffPage-feeTitle,
    .app__tariffPage-nav__smallscreen{
        display: none;
     }
    .app__tariffPage-nav {      
        display: flex;      
    }
  
    .app__tariffPage-nav__links {
        display: flex;
        flex-direction: row;
    }

    .app__tariffPage-nav__links li {
        text-align: center;
    } 
    .app__tariffPage-content {      
        padding: 50px;        
    } 

}

/*for-desktop-up*/
@media screen and (min-width: 1150px) {

    .app__tariffPage .app__tariff-title{
        width: 100%;
        margin-top: 50px;
    }
  
    
}
/*for-desktop-up*/
@media screen and (min-width: 2000px) {
    .app__tariffPage .app__tariff-title{
        margin-top: 150px;
    }
}