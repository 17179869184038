.app__bankaccounts{    
    display: flex;
    flex-direction: column;
    overflow: hidden;
}
.app__bankaccounts-title{
    width: 100%;
    background-color: black;
    opacity: 0.8;
    padding: 20px;
    margin-bottom: 50px;
    letter-spacing: 3px;
}
.app__bankaccounts-title p:nth-child(2){
   color: var(--color-golden);
   text-align: center;
   font-size: 18px;
}
.app__bankaccounts-content{
    display: flex;
    flex-direction: column;
    width:100%;
    align-items: center;
    justify-content: center;
    gap:30px;

}

.app__bankaccounts-content__item{
    display: flex;
    justify-content: center;
    /* background-color: var(--color-crimson); */
    background-color: #f5efdbd3;
    box-shadow: inset -3px 2px 20px 4px rgba(0, 0, 0, 0.75);
    opacity: 1;   
    border: 1px solid var(--color-crimson);
    width:100%;        
}

.app__bankaccounts-content__border{
    width:90%;  
    padding: 20px; 
}
.app__bankaccounts-content__border p{
    color: var(--color-black);
    font-family: var(--font-alt);
    font-size: 12px;   
    
}
.app__bankaccounts-content__border h4{
   font-size: 14px;
   padding-bottom: 10px;       
    
}

/*for-tablet*/
@media screen and (min-width: 650px) {   
    .app__bankaccounts-content__border h4{
      font-size: 16px;       
    }
  .app__bankaccounts-content__border p{       
        font-size: 15px;  
    }
    .app__bankaccounts-title p:nth-child(2){   
        font-size: 24px;
     }
    
}

/*for-desktop-up*/
@media screen and (min-width: 850px) { 
    .app__bankaccounts-content{
      flex-direction: row;
   }
   .app__bankaccounts-title p:nth-child(2){   
    font-size: 30px;
 }

}
/*for-desktop-up*/
@media screen and (min-width: 1150px) {    
    .app__bankaccounts-content__item{
      height:165px;         
    }
    
}
/*for-desktop-up*/
@media screen and (min-width: 2000px) { 
    .app__bankaccounts-content{
        width:80%;
     }    
}