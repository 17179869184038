.app__footer{
     /* height:100vh; */
     width: 100%;
} 
.app__footer-content{
    display:flex;
    flex-direction: column;
    gap:30px;
}
.app__footer-headtext {
    font-family: var(--font-base);
    color: var(--color-white);
    font-weight: 400;
    letter-spacing: 0.04em;
    text-transform: uppercase;
    font-size:15px;
    line-height: 41.6px;
    margin-bottom: 1rem;
}
.app__footer-contact,
.app__footer-logo,
.app__footer-work {
    flex: 1;
    margin: 3rem;
    text-align: center;
}

.app__footer-logo{
    display: flex;
    flex-direction: column;
    /* justify-content: center;  */
    align-items: center;  
}
.app__footer-logo p{
    text-align: center;
}
.app__footer-logo img:first-child {
    width:120px;   
}

.footer__copyright{ 
 display: flex;
 flex-direction: column;
 align-items: center;
}
.icon{
    width: 35px;
    height: 18px;
    margin-bottom: -3px;
}

@media screen and (min-width: 650px){
    .app__footer-headtext {      
       font-size: 20px;    
    }
}