.app__sphere {
  overflow: hidden;
}

.app__sphere-title h1 {
  text-align: center;
}

.app__sphere-wrapper {
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: 30px;
}

.app__sphere-content__section {
  display: flex;
  flex-direction: column;
  gap: 20px;
  width: 100%;
}

/* section */
.app__sphere-content__section-item {
  /* background-color: rgb(15, 17, 29); */
  background-color: rgb(15 17 29 / 58%);
  opacity: 0.7;
  border-radius: 10px;
}

.app__sphere-content__section-item .active {
  background-color: transparent;
}

.app__sphere-content__section-item__header {
  padding: 1.5rem 3rem 1.5rem 1rem;
  min-height: 3.5rem;
  line-height: 1.25rem;
  display: flex;
  align-items: center;
  position: relative;
  cursor: pointer;
}

.app__sphere-content__section-item__header h4 {
  pointer-events: none;
  color: var(--color-white);
  font-family: var(--font-alt);  
  font-weight: 100;
}

.app__sphere-content__section-item__header:hover h4,
.app__sphere-content__section-item__header:hover:after {
  color: var(--color-grey);
}

.app__sphere-content__section-item__header::after {
  content: "\002B";
  font-size: 1.8rem;
  position: absolute;
  right: 1rem;
  color: var(--color-white);
}

.app__sphere-content__section-item__header.active::after {
  content: "\2212";
}

.app__sphere-content__section-item__body {
  max-height: 0;
  overflow: hidden;
  transition: max-height 0.3s ease-out;
}

.app__sphere-content__section-item__body-text {
  padding: 0.8rem;
  line-height: 1.5rem;
  border-top: 3px solid;
  border-image: linear-gradient(to right, transparent, #52483d, transparent)1;
  text-indent: 20px;
}

.app__sphere-content__section-item__body-text ul li {
  list-style: none;
  color: var(--color-crimson);
  font-family: var(--font-alt);
  font-weight: 100;
  font-size: 14px;
  padding: 3px; 
}

.app__sphere-content__section-item__body-text ul li:before {
  content: "\FE63";
  margin: 0 5px 0 -10px;
}

.app__sphere-content__section-item__body-text p {
  color: var(--color-white);
  font-family: var(--font-alt);  
  font-size: 14px;
  font-weight: 100;
  padding: 3px;
}

.app__sphere-content__section-item__body-text a {
  font-family: var(--font-alt);
  background-color: rgb(15, 17, 29);
  word-wrap: break-word;
  color: var(--color-golden);
}

.app__sphere-content__section-item__body-text a:hover {
  text-decoration: underline;
}

/* section-c */

.app__sphere-content__section-c {
  display: flex;
  flex-direction: column;
  gap: 20px;
}

.app__sphere-content__section-c-item {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 100%;

  padding: 10px 6px;
  height: max-content;
  opacity: 0.7;
}


.app__sphere-content__section-c-item ul {
  width: 100%;
  padding: 30px 5px 15px 10px;
  list-style: none;
}

.app__sphere-content__section-c-item ul li {
  padding: 5px;
  line-height: 20px;
  font-size: 14px;
  font-weight: 100;
}

.app__sphere-content__section-c-item h4 {
  font-family: var(--font-alt);
  font-weight: 100;
  color: var(--color-white);
  padding-bottom: 10px;
  padding-top: 10px;
  border-bottom: 1px dotted var(--color-grey);  
}

.app__sphere-content__section-c-item p {
  font-family: var(--font-alt);
  color: var(--color-white);
}

.app__sphere-content__section-c-item a {
  font-family: var(--font-alt);
  color: var(--color-golden); 
  word-wrap: break-word;
}

.app__sphere-content__section-c-item a:hover {
  text-decoration: underline;
}

@media screen and (min-width: 650px) {

  .app__sphere-content__section-item,
  .app__sphere-content__section-c-item {
    padding: 15px;
  }

  .app__sphere-content__section-item__header h4,
  .app__sphere-content__section-c-item h4 {
    font-size: 20px;
  }

  .app__sphere-content__section-item__body-text ul li,
  .app__sphere-content__section-item p{
    font-size: 16px;
    line-height: 26px;
  }
  
  .app__sphere-content__section-c-item ul li {
    font-size: 14px;
    line-height: 22px;
  }
}

@media screen and (min-width: 1150px) {
  .app__sphere-wrapper {
    display: flex;
    flex-direction: row;
    gap: 80px;
  }
  .app__sphere-title{
    padding: 50px 0;
  }
  .app__sphere-content__section {
    width: 58%;
  }

  .app__sphere-content__section-c {
    width: 42%;
  }

  .app__sphere-content__section-item {
    padding: 30px 20px;
  }

  .app__sphere-content__section-c-item {
    border-left: 2px dotted var(--color-gray);
    padding-left: 20px;
  }

  .app__sphere-content__section-item__header h4,
  .app__sphere-content__section-c-item h4 {
    font-size: 22px;
    line-height: 30px;
  }

  .app__sphere-content__section-item__body-text ul li,
  .app__sphere-content__section-item p{
    font-size: 18px;
    line-height: 30px;
  }
  .app__sphere-content__section-c-item ul li {
    font-size: 18px;
    line-height: 26px;
  }
}
