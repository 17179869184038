.app__blanks-container {
    display: flex;
    flex-direction: column;
    gap:20px;   
}

.app__blanks-container__item {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    width: 100%;
    padding: 10px;    
    background-color: black;
    opacity: 0.8;
    border: 2px solid var(--color-crimson);
    transition: all 0.6s ease-out;
    line-height: 1.5;
    box-shadow: -3px 2px 20px 4px rgba(0, 0, 0, 0.75);
}
.app__blanks-container__item:hover{
    opacity: 0.9;
    border: 2px solid var(--color-golden);
}

.app__blanks-container__item h3,.app__blanks-container__item svg {
    font-family: var(--font-alt);
    color: var(--color-crimson);
    font-size: 18px;
    font-weight: 100;
}
.app__blanks-container__item h3 {
    width:90%;
}
.app__blanks-container__item :hover h3,
.app__blanks-container__item :hover svg {
    color: var(--color-golden);
}
.app__blanks-container__item .row{   
    display: flex;
    width: 100%;   
    align-items: center;
    justify-content: space-between;
    padding: 5px;
}
/*for-desktop-up*/
@media screen and (min-width: 1150px) {
    .app__blanks-container__item {
        width:80%;
        padding: 20px;
    }
}