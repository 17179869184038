.slider {
    overflow: hidden;
    width: 100%;
    height: 60vh;
    position: relative;
   }
    
   .slider .slide {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-size: cover;
    background-position: center;
    animation: slider 12.5s infinite;
   }

    
.slider .slide:nth-child(1) {
    background-image: url('https://images.unsplash.com/photo-1595356700395-6f14b5c1f33f?ixlib=rb-1.2.1&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=1000&q=80');
    animation-delay: 0s;
 }
 
 .slider .slide:nth-child(2) {
    background-image: url('https://images.unsplash.com/photo-1591019052241-e4d95a5dc3fc?ixlib=rb-1.2.1&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=1000&q=80');
    animation-delay: -2.5s;
 }
 
 .slider .slide:nth-child(3) {
    background-image: url('https://images.unsplash.com/photo-1543373014-cfe4f4bc1cdf?ixlib=rb-1.2.1&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=1000&q=80');
    animation-delay: -5s;
 }
 
 .slider .slide:nth-child(4) {
    background-image: url('https://images.unsplash.com/photo-1517430554953-a5ba4678fe85?ixlib=rb-1.2.1&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=1000&q=80');
    animation-delay: -7.5s;
 }
 
 .slider .slide:nth-child(5) {
    background-image: url('https://images.unsplash.com/photo-1583161178154-c362b3459d29?ixlib=rb-1.2.1&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=1000&q=80');
    animation-delay: -10s;
 }
 
 @keyframes slider {
  0%, 16%, 100% {
    transform: translateX(0);
    animation-timing-function: ease;
  }
  20% {
    transform: translateX(-100%);
    animation-timing-function: step-end;
  }
  96% {
    transform: translateX(100%);
    animation-timing-function: ease;
  }
 }