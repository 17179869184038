@import url('https://fonts.googleapis.com/css2?family=Cormorant+Upright:wght@300;400;500;600;700&family=Open+Sans:wght@300;400;500;600;700&display=swap');

:root {
  --font-base: 'Cormorant Upright', serif;
  --font-alt: 'Open Sans', sans-serif;
  --color-golden: #DCCA87;
  --color-blue: #030D31;
  --color-blue-100: #000511;

  --color-blue-200:#00203E;

  --color-blue-300:#27366D;
  --color-blue-400:#265DB8;
  --color-black: #0C0C0C;
  --color-gray: #545454;
  --color-crimson: #F5EFDB;
  --color-grey: #AAAAAA;
  --color-white: #FFFFFF;
  --color-ocher-50:#9E7458;
  --color-ocher-100:#C1792E;
  --color-ocher-200:#8A470D;
}

* {
  box-sizing: border-box;
  padding: 0;
  margin: 0;
  scroll-behavior: smooth;
}

a {
  color: unset;
  text-decoration: none;
  outline: none;
}

.slide-right {
  -webkit-animation: slide-right 0.8s cubic-bezier(0.250, 0.460, 0.450, 0.940) both;
	animation: slide-right 0.8s cubic-bezier(0.250, 0.460, 0.450, 0.940) both;
}
.slide-left {
  -webkit-animation: slide-left 0.8s cubic-bezier(0.250, 0.460, 0.450, 0.940) both;
	animation: slide-left 0.8s cubic-bezier(0.250, 0.460, 0.450, 0.940) both;
}
.slide-bottom {
  -webkit-animation: slide-bottom 0.8s cubic-bezier(0.250, 0.460, 0.450, 0.940) both;
	animation: slide-bottom 0.8s cubic-bezier(0.250, 0.460, 0.450, 0.940) both;
}
.fade {
  -webkit-animation: fade 0.8s cubic-bezier(0.250, 0.460, 0.450, 0.940) both;
	animation: fade 0.8s cubic-bezier(0.250, 0.460, 0.450, 0.940) both;
}
/* Fading animation */
.fade-in-out {
  animation: fade-in-out 10s infinite ease-out;
  -webkit-animation: fade-in-out 10s infinite ease-out; /* Webkit */
}

.flow {
  animation: flow-left 3s ease-out;
  -webkit-animation: flow-left 3s ease-out; /* Webkit */
}
.fade-in {
  animation: fade-in 0.5s ease-in; 
}

@keyframes slide-right {
  0% {
    -webkit-transform: translateX(-25%);
            transform: translateX(-25%);
            width:0;
  }
  100% {
    -webkit-transform: translateX(0);
            transform: translateX(0);
            width:90%;
  }
}

@keyframes slide-left {
  0% {
    -webkit-transform: translateX(0);
    transform: translateX(0);
    width:90%;
   
  }
  100% {
    -webkit-transform: translateX(-25%);
            transform: translateX(-25%);
            width:0;
  }
}

@keyframes slide-bottom {
  0% {
    -webkit-transform: translateY(-15%);
    transform: translateY(-15%);
    
  }
  100% {
    -webkit-transform: translateY(0);
            transform: translateY(0);          
       
    }   
}

@keyframes fade {
  0% {
   
    opacity: 1;    
   
  }
  50% {  
    opacity: 0;  
   
  } 
   100% {
  
       opacity: 0;
  }
}

@keyframes fade-in {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}

@keyframes fade-out {
  from {
    opacity: 1;
  }
  to {
    opacity: 0;
  }
}

@keyframes fade-in-out {
  0% {
    opacity: 0.5;
  }
  50% {
    opacity: 1;
  }
  75% {
    opacity: 1;
  }
  100% {
    opacity: 0.6;
  }
}

@keyframes flow-left {
  0% {
    left: 0%;
    width: 100%;
    opacity: 0.5;
  }
  100% {
    left: 100%;
    width: 0;
    opacity: 0.7;
  }
}

@keyframes flow-left {
  0% {
    left: 0%;
    width: 100%;
    opacity: 0.5;
  }
  100% {
    left: 100%;
    width: 0;
    opacity: 0.7;
  }
}