.app__team {
  display: flex;
  flex-direction: column;
  padding-top: 100px;
  width:100%;
  overflow: hidden;
}
.app__team-intro{
    padding-top: 30px;
    width:100%;
}
.app__team-intro__title{
    text-align: center;
}

.app__team-intro__image  {
  display:flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 300px;
}
.app_team-img {
  position: relative;
  display: inline-block;
  width: 90%;
  height: 250px;  
}
.app_team-img img {
  display: block;
  width: 100%;
  height: 100%;
}

.app__team-img__title {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  text-align: center;
}
.app__team-head {
    width: 100%; 
   
  }
  .app__team-wrapper{
    background-color: var(--color-blue-200);
    border:1px dotted var(--color-gray);
    padding:25px;
    opacity: 0.6;
  }
  .app_team-head__info-content{   
    padding-top:50px;
  }
  .app_team-head__info-content p{
    /* text-indent: 20px;     */
    padding: 10px 0;
    line-height: 25px;
  }
  .app__team-empl{
    padding-top: 50px;
  }
  .app__team-empl p span{    
    color: var(--color-grey);
  }
  .app__team-empl p:last-child{
    padding-top: 15px;
  }
/*For-tablet-portrait-up */
@media screen and (min-width: 650px) {
  .app__team-intro__image  {
    height: 350px;
  }
  .app_team-img {
    height: 300px;
    width: 80%;
  }
  .app__team-wrapper{      
    padding:50px;        
  }
}
/*for-desktop-up*/
@media screen and (min-width: 850px) {
    .app__team-intro__image  {
        height: 400px;     
      }
      .app_team-img {
        height: 350px;
       
      }
      .app__team-intro__title{
        padding-bottom: 50px;        
      }
      .app_team-img {
       width:90%;
      }
   
}
/*for-desktop-up*/
@media screen and (min-width: 1150px) {
    .app__team {       
        flex-direction: row;       
      }
      .app_team-img {
        padding-left: 50px;
       }
     
}
@media screen and (min-width: 1450px) {
    .app__team-intro__image  {
        height: 500px;     
      }
      .app_team-img {
        height: 450px;
      }
}

@media screen and (min-width: 2000px) {
    .app__team-intro__image  {
        height:600px;     
      }
      .app_team-img {
        height: 550px;
      }
}

