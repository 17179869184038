.app__tariff {
    /* background: url("../../assets/bg4.jpg"); */
    width:100%;
    display: flex;
    flex-direction: column;
    /* padding-top: 200px; */
    overflow: hidden;
}

.app__tariff-title {
    display: flex;
    flex-direction: column;
    align-items: center;
    /* background-color: black; */
    background-color: #000000bd;
    opacity: 0.8;
    width: 100%;
    border-radius: 10px;
    padding: 50px;
}
.app__tariff-title p{  
   text-align: center;
}

.app__tariff-content {
    width: 100%;
    gap: 10px;
    display: flex;
    flex-direction: column;
    margin-top: 20px;
}

.app__tariff-content_item {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    width: 100%;
    padding: 15px;
    padding-left: 30px;
    background-color: black;
    box-shadow: -3px 2px 20px 4px rgba(0, 0, 0, 0.75);
    opacity: 0.8;
    border: 2px solid var(--color-crimson);
    transition: all 0.6s ease-out;
}

div .app__tariff-content_item:hover {
    opacity: 0.9;
    border: 2px solid var(--color-golden);
}

.app__tariff-content :hover h2,
.app__tariff-content :hover svg {
    color: var(--color-golden);
}


.app__tariff-content_item h2 {
    color: var(--color-crimson);
    font-family: var(--font-alt);
    font-size: 18px;
    font-weight: 100;
}

.app__tariff-icon {
    color: var(--color-crimson);
    width: 68px;
    height: 45px;
}
@media screen and (min-width:450px){
    .app__tariff-content_item h2 {        
        font-size: 24px;       
    }
}

/*for-desktop-up*/
@media screen and (min-width: 1150px) {
    .app__tariff-content {
        width: 80%;
    }
    .app__tariff-title {       
        width: 80%;           
    }
    .app__tariff-content_item {        
        padding: 20px;
       
    }
}