.app__services {
    background-color: var(--color-blue-200);
    display: flex;
    flex-direction: column;
    gap: 20px;
    overflow: hidden;
}
.app__services-title{
    display: flex;
    flex-direction: column;
    align-items: center;
}

.app__services-content {
    padding-top: 0;
    gap: 10px;
    display: flex;
    flex-direction: column;
}
.app__services-section {
    display: flex;
    justify-content: center;
    align-items: center;
    box-shadow: -3px 2px 20px 4px rgba(0, 0, 0, 0.75);
    cursor: pointer;
    transition: all 0.5s ease-in-out;
    background-color: transparent;
    width: 100%;
    height: 350px;
}

.app__services-section_item {
    width: 100%;
    height: 100%;
}

.app__services-section_item-img {
    width: 100%;
    height: 80%;
}

.app__services-section_item-img img {
    width: 100%;
    height: 100%;
}

.app__services-section_item-content {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    background-color: black;
    height: 20%;
    opacity: 0.5;
    transition: all 0.5s ease-in-out;
}

.app__services-section:hover {
    box-shadow: inset 2px -1px 17px 1px rgba(97, 120, 179, 1);
}

.app__services-section:hover .app__services-section_item-content h3,
.app__services-section:hover .app__services-section_item-content svg {
    color: var(--color-crimson);  
   }

   .app__services-section_item h3,
.app__services-section_item svg {
    color: var(--color-golden);
    font-family: var(--font-alt);
}
.app__services-icon {
    color: var(--color-blue-200);
    width: 55px;
    height: 20px;
    margin-left: 5px;
}

@media screen and (min-width: 1024px) {
    .app__services-content {
        flex-direction: row;       
        justify-content: center;
        flex-wrap: wrap;
        gap: 20px;       
    }
    .app__services-section {     
        width: 450px;
    }

    .app__services-section_item {
        width: 100%;
    }

    .app__services-section_item h3 {
        width: 300px;
    }
    
}

/*for-desktop-up*/
@media screen and (min-width: 1150px) {
    .app__services-section_item h3 {
        font-size: 18px;
    } 
    .app__services-icon {        
        width: 33px;      
    }
}



