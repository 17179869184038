.app__aboutus {
    background-color: var(--color-blue-200);   
    display:flex;
    flex-direction: column;
    gap:10px;  
    height:auto; 
    overflow: hidden;
}
.app__aboutus-content{
display: flex;
flex-direction: column;
justify-content: center;
align-items: flex-start;
gap:10px;
}
.app__aboutus-content span{
   margin-bottom: 20px;
    }

.app__aboutus-image {
  width: 100%;
  height: auto;
  padding-top: 40px;
  padding-bottom: 40px;
}
.app__aboutus-image img {
  width: 100%;
 
}

.app__aboutus-nav{
    display: flex;
    align-self: center;
    margin-top: 50px;
}

/*For-tablet-portrait-up */
@media screen and (min-width: 650px) {  
 
}
/*for-desktop-up*/
@media screen and (min-width: 1150px) {   
    .app__aboutus {           
        display:flex;
        flex-direction: column;
        justify-content: space-between;
        
    }
    .app__aboutus-info{
        display: flex;
        justify-content: space-between;


    }
    .app__aboutus-section{
        width: 40%;
        display: flex;
        flex-direction: column;      
        justify-content: center;
        gap:20px;
    }
    .app__aboutus-section:nth-child(2){       
        flex-direction: column-reverse;   
    }
    .app__aboutus-nav{        
        align-self: flex-start;        
    }
.app__aboutus-image img {
    width: 80%;
}
}
/*for-big-desktop-up*/
@media screen and (min-width: 2000px) {
 
}




