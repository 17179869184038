.app__contacts{
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    gap:30px;      
 }
 .app__contacts-title{
    display: flex;
    flex-direction: column;
    align-items: start;
 }
 .app__contacts-content{
    display: flex;
    flex-direction: column; 
    gap:30px;
    width:100%;
    height: 100%;
 }
 
.app__contacts-map{
    display: block;
    width: 100%;
    height: 400px;
    /* height: auto; */
    padding: 30px;
    border: 2px solid gray;
    overflow: hidden;
    position: relative;
}

.app__contacts-map iframe {
    width: 100%;
    height: 100%;
  }



@media screen and (min-width: 650px) {
    .app__contacts-map{       
        height: 585px;      
    }
  
}
/*for-desktop-up*/
@media screen and (min-width: 1150px) {
    /* .app__contacts{
       align-items: flex-start;          
     } */
     .app__contacts-content{
        display: flex;
        flex-direction: row;      
        width: 100%;
        gap:10px;
     }
     .app__contacts-map{       
        height: auto;      
    }

}

@media screen and (min-width: 2000px) {
    .app__contacts-content{
       gap:30px;
     }   
}