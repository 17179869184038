.app__contactform-container{
    display: grid;
    width: 100%;
    border: 2px solid var(--color-blue-200);
    height: 40%;
    border: 2px solid gray;
    position: relative;
    overflow: hidden;
}
.app__contactform-container__form{
    display: flex;
    flex-direction: column;
    gap:20px;
    padding: 30px;
    border: 2px solid var(--color-blue-200);
    box-shadow: -3px 2px 20px 4px rgba(0, 0, 0, 0.75);    
}
.app__contactform-container__form input,
.app__contactform-container__form textarea{    
 width:100%;
 padding: 20px;
 outline: none;
 background-color: black;
 opacity: 0.5;
 font-size: 14px;
 -webkit-box-shadow: inset 2px -1px 17px 1px rgba(97,120,179,1);
 -moz-box-shadow: inset 2px -1px 17px 1px rgba(97,120,179,1);
 box-shadow: inset 2px -1px 17px 1px rgba(97,120,179,1);
 color:white;
}
.app__contactform-container__form input::placeholder{
color: white;
z-index: 1;
font-size: 16px;
}

.app__contactform-container__form textarea::placeholder{
    color: white;
    font-size: 18px;
}
.app__contactform-container__form button{
width:60%;
align-self: center;
margin-top: 50px;
}
.success-container{
    display:grid;
    width: 100%;
    height: 100%;
    position: absolute;
   
    z-index: 1;
}
.success{    
    background-color: #c6d5dd;
    text-align: center;
    justify-self: center;
    width:80%;
    position: absolute;
    padding: 50px;   
    font-family: var(--font-base);
    font-size: 16px;
    letter-spacing: 2px;
  font-weight: 600;
    color:#0f6d0f;
    box-shadow: -3px 2px 20px 4px rgba(0, 0, 0, 0.75);
    border-radius: 5px;
   
    top: 150px;
}
.error{
    color: rgb(252, 1, 1);
    letter-spacing: 2px;
}

/*For-tablet-portrait-up */
@media screen and (min-width: 650px) { 
    .app__contactform-container__form input,
    .app__contactform-container__form textarea{ 
     font-size: 20px;     
    }

}
/*for-desktop-up*/
@media screen and (min-width: 850px) { 
    .app__contactform-container{
       width: 100%;
   }
   .app__contactform-container__form input,
    .app__contactform-container__form textarea{ 
     font-size: 24px;     
    }
    /* .success{        
        font-size: 20px;        
    }
    .success{
        width:104%
     } */
 
}
/*for-desktop-up*/
@media screen and (min-width: 1150px) {
    /* .app__contactform-container {
        width: 100%;

    }

    .success-container {
        width:100%;   
   
    } */
}
@media screen and (min-width: 1450px) { 
 
    /* .success-container {
        width: 206%;     
    } */
}

@media screen and (min-width: 2000px) { 
 
    /* .success-container {
        width: 206%;     
    }
    .success{
        width:102%
     } */
 
}

