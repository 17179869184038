.app__banners {
    display: flex;
    flex-direction: column;
    justify-content: center;
    padding-top: 105px;
    width:100%;
    height: 230px;
}

.app__banners-content {
    height: 80%;
}

.app__banners img {
    width: 100%;
    height: 100%;
}


@media screen and (min-width: 450px) {
    .app__banners {
        height: 250px;
    }
}

@media screen and (min-width: 650px) {
    .app__banners {
        height: 300px;
    }
}

@media screen and (min-width: 850px) {}

@media screen and (min-width: 1024px) {}

/*for-desktop-up*/
@media screen and (min-width: 1150px) {
    .app__banners {
        height: 400px;
        padding-top: 125px;
    }
}

/*for-desktop-up*/
@media screen and (min-width: 2000px) {
    .app__banners {
        height: 700px;
        padding-top: 190px;
    }
}