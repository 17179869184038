.section__padding {
  padding: 4rem 2rem;
}

.flex__center {
  display: flex;
  justify-content: center;
  align-items: center;
}

.app__bg {
  background: url('./assets/bg4.webp');
  background-position: center;
  background-size: cover;
  background-repeat: repeat;
  background-attachment: fixed;

}

.app__bg2 {
  background: url('./assets/bg5.webp');
  background-position: center;
  background-size: cover;
  background-repeat: repeat;
  background-attachment: fixed;

}

.app__bg3 {
  background: url('./assets/bg3.webp');
  background-position: center;
  background-size: cover;
  background-repeat: repeat;
  background-attachment: fixed;
}

.app__bgblue {
  background: url('./assets/bg7.webp');
  background-position: center;
  background-size: cover;
  background-repeat: repeat;
  background-attachment: fixed;
}

.app__wrapper {
  display: flex;
  justify-content: space-between;
  align-items: center;

  min-height: 100vh;
}


.custom__button {
  background-color: #010C16;
  color: var(--color-golden);
  font-family: var(--font-base);
  font-weight: 700;
  letter-spacing: 0.04em;
  line-height: 28px;
  font-size: 18px;
  padding: 0.5rem 1.5rem;
  border: 0.5px solid gray;
  border-radius: 1px;
  outline: none;
  cursor: pointer;
  opacity: 0.9;
  transition: all 0.5s ease-in-out;

}

.button-sm {
  line-height: 20px;
  font-size: 8px;
  padding: 2px 15px;
  background-color:var(--color-blue-200);
  color: var(--color-golden);
  font-family: var(--font-base);
  font-weight: 100;
  letter-spacing: 0.04em;
  border: 0.5px solid gray;
  border-radius: 1px;
  outline: none;
  cursor: pointer;
  opacity: 0.9;
  transition: all 0.5s ease-in-out;
}

button:hover {
  box-shadow: inset 2px -1px 10px 1px rgb(48, 63, 100);
  color:var(--color-grey);
}

.p__cormorant {
  font-family: var(--font-base);
  color: var(--color-white);
  font-weight: 400;
  letter-spacing: 0.04em;
  /* text-transform: capitalize; */
  font-feature-settings: 'tnum' on, 'lnum' on;
  line-height: 29.9px;
  font-size: 19px;
}

.p__opensans {
  font-family: var(--font-alt);
  color: var(--color-white);
  font-weight: 400;
  letter-spacing: 0.04em;
  /* text-transform: capitalize; */
  line-height: 28px;
  font-size: 12px;
}
.p__custom {
  font-family: var(--font-alt);
  font-size: 12px;
  padding: 5px 0;
}



.headtext__cormorant {
  font-family: var(--font-base);
  color: var(--color-golden);
  font-weight: 400;
  letter-spacing: 0.04em;
  /* text-transform: capitalize; */
  line-height: 40px; 
  font-size: 20px;
}

@media screen and (min-width: 650px) {
  .section__padding {
    padding: 4rem;
  }

  .p__cormorant {
    font-size: 21px;
  }

  .p__opensans {
    font-size: 14px;
  }

}

@media only screen and (max-width:767px) {

  .app__bg,
  .app__bg2,
  .app__bg3 {
    background-size: contain !important;
  }
}

@media screen and (min-width: 850px) {
  .section__padding {
    padding: 4rem 6rem;
  }

  .button-sm {
    line-height: 30px;
    font-size: 14px;
    padding: 5px 15px;
  }
  .headtext__cormorant {
    font-size: 30px; 
    line-height: 70px;   
  }
  .p__custom {
    font-size: 16px;
    padding: 10px 0;
  }
  
}

@media screen and (min-width: 1150px) {
  .button-sm {
    font-size: 18px;
    padding: 10px 25px;
  }

  .p__opensans {
    font-size: 16px;
  }
  .headtext__cormorant {
    font-size: 32px;    
  }
  .p__custom {
    font-size: 18px;    
  }
}

@media screen and (min-width: 2000px) {

  .custom__button,
  .p__cormorant {
    font-size: 37px;
    line-height: 67px;
  }

  .p__opensans {
    font-size: 30px;
    line-height: 50px;
  }

  .headtext__cormorant {
    font-size: 60px;
    line-height: 210px;
  }
  .p__custom {
    font-size: 20px;    
  }
  .button-sm {
    font-size: 20px;
  }
}
