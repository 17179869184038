.topbtn .top,
.topbtn .topHide {
  position: fixed;
  bottom: 65px;
  right: 30px;  
  color: var(--color-grey); 
  transition: all 0.3s ease-in-out;  
  z-index: 99;
}
.topbtn .top{ 
  opacity: 1;
  animation: fade-in 1s;
}
.topbtn .topHide{ 
  opacity: 0;
  animation: fade-out 1s;
}
.topbtn svg {
  width: 50px;
  height: 50px;
}
/* @keyframes fade-in {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}

@keyframes fade-out {
  from {
    opacity: 1;
  }
  to {
    opacity: 0;
  }
} */
