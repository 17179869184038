.app__carousel {
  height: 250px;
  position: relative;
  /* overflow: hidden; */
}
.app__carousel-inner {
}
.app__carousel-item {
  position: relative;
}
.app__carousel-wrapper {
  height: 250px;
}

.app__carousel-wrapper:hover {
  animation-name: none;
}
.app__carousel-wrapper img {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 80%;
}

.app__carousel-wrapper__content {
  width: 100%;
  gap: 10px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  position: absolute;
  top: 35%;
  left: 50%;
  transform: translate(-50%, -50%);  
}
.app__carousel-wrapper__content-title{
  text-align: center;  
}

.app__carousel-wrapper__content-title h1 {  
  color: white;
  font-size: 20px;
  text-align: center;  
}
.app__carousel-wrapper__content-title p{
  color: white;
  padding: 5px;
  font-size: 14px;
}
.app__carousel-wrapper__content-title :nth-child(2){    
  margin-top: 10px;     
 }
.app__carousel_arrows { 
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  position: absolute;
  top: 20%;
  padding: 0px 1rem; 
}
.app__carousel_arrow-icon { 
  font-size: 3rem;
  cursor: pointer; 
  color: white;
  background-color: var(--color-blue-200);
  opacity: 0.1;     
 
}
.app__carousel_arrow-icon:hover{
  opacity: 0.5;
}

 


/* Fading animation */
/* .fade-in-out {
  animation: fade-in-out 10s infinite ease-out;
  -webkit-animation: fade-in-out 10s infinite ease-out; Webkit 
} */

/* .flow {
  animation: flow-left 3s ease-out;
  -webkit-animation: flow-left 3s ease-out; 
} */

/* @keyframes fade-in-out {
  0% {
    opacity: 0.5;
  }
  50% {
    opacity: 1;
  }
  75% {
    opacity: 1;
  }
  100% {
    opacity: 0.6;
  }
}
@keyframes flow-left {
  0% {
    left: 0%;
    width: 100%;
    opacity: 0.5;
  }
  100% {
    left: 100%;
    width: 0;
    opacity: 0.7;
  }
} */


/*For-tablet-portrait-up */
@media screen and (min-width: 450px) {
  .app__carousel-wrapper__content {    
    top: 50%;
    gap:50px;  
  }
  .app__carousel-wrapper__content-title p{    
    padding: 10px;
    font-size: 14px;
  }
  .app__carousel-wrapper__content-title :nth-child(2){    
    margin-top: 20px;     
   }
  .app__carousel-wrapper img {   
    height: 100%;
  }
  .app__carousel_arrows {
    padding: 0px 2rem;
  }
  .app__carousel_arrow-icon {
    height: 1.5em;
    width: 1.5em;
  }

}

/*For-tablet-portrait-up */
@media screen and (min-width: 650px) {
  .app__carousel-wrapper {
    height: 400px;
  }
  .app__carousel {
    height: 400px;
  }
  .app__carousel-wrapper__content-title h1 {
    font-size: 32px;
  }
  .app__carousel-wrapper__content-title p {
    font-size: 20px;
  }
  .app__carousel_arrows {
    top: 40%;
    padding: 0px 3rem;
  }

 
}
/*for-desktop-up*/
@media screen and (min-width: 1150px) {
  .app__carousel {
    height: 500px;
  }
  .app__carousel-wrapper {
    height: 500px;
  }
  .app__carousel_arrows {
   
    padding: 0px 4rem;
  }
  .app__carousel_arrow-icon {
    height: 2em;
    width: 2em;
  }
}
/*for-big-desktop-up*/
@media screen and (min-width: 1500px) {
  .app__carousel {
    height: 550px;
  }
  .app__carousel-wrapper {
    height:550px;
  }
  .app__carousel-wrapper__content h1 {
    font-size: 48px;
  }
  .app__carousel_arrows {   
    padding: 0px 5rem;
  }
}
/*for-big-desktop-up*/
@media screen and (min-width: 2000px) {
  .app__carousel {
    height: 850px;
  }
  .app__carousel-wrapper {
    height:850px;
  }
  .app__carousel-wrapper__content h1 {
    font-size: 48px;
  }
  .app__carousel_arrows {   
    padding: 0px 5rem;
  }
}

.indicators {
  display: flex;
  position: absolute;
  bottom: 1rem;
  left: 50%;
  transform: translate(-50%, -50%);
}

.indicator {
  background-color: white;
  height: 1rem;
  width: 1rem;
  border-radius: 100%;
  border: none;
  outline: none;
  box-shadow: 0px 0px 5px #555;
  margin: 0 0.2rem;
  cursor: pointer;
}

.indicator-inactive {
  background-color: grey;
}
