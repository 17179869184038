.app__range {
    background-color: var(--color-blue-200);
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    gap: 30px; 
    overflow: hidden;
}
.app__range-title {
    display: flex;
    flex-direction: column;
    align-items: center;
  }
  .app__range-title h1 {
    text-align: center;
  }
.app__range-title span:hover{
    color: var(--color-crimson);
}
.app__range-container{
    display:flex;
    flex-direction: column;
    gap:30px;
    padding-top: 0;
}

.app__range-content {
    display: flex;
    flex-direction: column;
    width: 100%;
}

.app__range-content__map {
    width: 100%;
    height: 400px;
}

.app__range-content__map iframe {
    width: 100%;
    height: 100%;
}

.app__range-info{
    border:1px dotted var(--color-gray);
    padding:10px;   
}
.app__range-info p{   
    padding:10px;     
}

/*for-desktop-up*/
@media screen and (min-width:1150px) {  
    .app__range-content {
        display: flex;
        flex-direction: row-reverse;
        justify-content: space-between;
    }

    .app__range-content__map {
        width: 48%;
        height: 780px;
    }  
    .app__range-info{     
        padding:30px;       
    } 
}
