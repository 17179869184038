.app__notfound{
  width: 100%; 
  padding-top: 200px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
  height: 500px;   
}

.app__notfound h1{ 
  font-size: 170px;
}
.app__notfound p{
   color: var(--color-crimson);
   font-size: 16px;
   text-align: center;
  }