.app__powers {
  background-color: var(--color-blue-200);
  display: flex;
  flex-direction: column;
  gap: 20px;
  overflow: hidden;
}

.app__powers-title {
  display: flex;
  flex-direction: column;
  align-items: center;
}
.app__powers-title h1 {
  text-align: center;
}

.app__powers-content {
  flex-direction: row;
  justify-content: center;
  gap: 20px; 
  padding-top:10px;
  padding-bottom: 10px;
}
.app__powers-border{
  border:1px dotted var(--color-gray);
  padding: 10px 10px;
  width:100%;
}
.app__powers-border p{
  padding-bottom: 15px;
}
.app__powers-content__list{
  width:100%;
 padding-left: 10px;
}
.app__powers-content__list li{
list-style:disc;
color:var(--color-white);
}


/*for-desktop-up*/
@media screen and (min-width: 650px) {
  .app__powers-border{  
    padding: 30px 30px;
    
  }
}


