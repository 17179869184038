.app__districtcourts {
  margin-top: 50px;
}
.app__districtcourts-town { 
  background-color: var(--color-crimson);
  padding: 10px;
  padding-left: 20px; 
  box-shadow: inset -3px 2px 10px 2px rgba(0, 0, 0, 0.75);
}

.app__districtcourts-town h2 {
  font-family: var(--font-alt);
  font-size: 12px;
  color: var(--color-blue-200);
  padding-bottom: 10px;
}
.app__districtcourts-town h2:hover {  
  color: var(--color-golden); 
}
.app__districtcourts-town p {
  font-family: var(--font-alt);
  font-size: 12px;
  color: var(--color-black);
  padding-bottom: 10px;
}

.app__districtcourts-item {
  display: flex;
  align-items: center;
  justify-content: space-between;
  color: white;
  list-style: none;
  cursor: pointer;
  padding: 10px 15px;
  /* background-color: var(--color-black);
  opacity: 0.6; */
  background-color: rgba(12, 12, 12, 0.568);
  border: 1px solid var(--color-grey);
  margin: 5px 0;
  font-size: 14px;  
  transition: all 0.5s ease-in-out;
  
}
.app__districtcourts-list li:hover{
  color:var(--color-golden);
opacity: 0.7;
 
}
.app__districtcourts-list li:hover svg{
  color:var(--color-golden);
 
}

.app__districtcourts-item span {
  display: flex;
  justify-content: space-between;
  width: 90%;
  font-size: 12px;
  line-height: 30px;
}
.app__districtcourts-item span svg {       
    width:15px;
    height:15px;
  }

  @media screen and (min-width: 650px){
    .app__districtcourts-item {       
        font-size: 20px;
      }
    .app__districtcourts-item span {       
        font-size: 16px;
        line-height: 60px;
      }
      .app__districtcourts-item span svg {       
        width:20px;
        height:20px;
      }
}
@media screen and (min-width: 850px){
    .app__districtcourts-item {       
        font-size: 30px;
      }
    .app__districtcourts-item span {       
        font-size: 20px;
        line-height: 60px;
      }
      .app__districtcourts-item span svg {       
        width:30px;
        height:30px;
      }
      .app__districtcourts-town h2 {      
        font-size: 16px;      
      }
      .app__districtcourts-town p {      
        font-size: 14px;      
      }
}

@media screen and (min-width: 1150px){
  .app__districtcourts {       
      width:48%;
      margin-top: 0;
    }
    .app__districtcourts-list :first-child{       
     margin-top: 0;
    }
    .app__districtcourts-town h2 {      
      font-size: 20px;      
    }
    .app__districtcourts-town p {      
      font-size: 16px;      
    }

}