.app__navbar {
    position: fixed;
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center;
    background: var(--color-blue-200);
    padding: 1rem;
    -webkit-box-shadow: 0px 3px 5px 0px rgba(0, 0, 0, 0.6);
    -moz-box-shadow: 0px 3px 5px 0px rgba(0, 0, 0, 0.6);
    box-shadow: 0px 3px 5px 0px rgba(0, 0, 0, 0.6);
    z-index: 2; 
 }

.app__navbar-logo {
    display: flex;
    justify-content: flex-start;
    align-items: center;
}

.app__navbar-logo img {
    width: 80px;
}

.app__navbar-logo_title {
    display: flex;
    flex-direction: column;
    justify-content: center;
}

.app__navbar-logo_title h2 {
    text-transform: uppercase;
    letter-spacing: 2px;
    line-height: 20px;
}

.app__navbar-logo_title p {
    line-height: 20px;
}
.app__navbar__menu-icon {
    position: absolute;
    top: 10px;
    right: 10px;
}

.app__navbar-links {
    flex: 1;
    display: none;
    justify-content: center;
    align-items: center;
    list-style: none;
}

.app__navbar-links li {
    margin: 0 1.5rem;
    cursor: pointer;
    position: relative;
    display: flex;
    align-items: center;   
}
.app__navbar-links li a{
   display: flex;
   align-items: center;
}

.app__navbar-links li:hover {
    color: var(--color-grey);
}

.app__navbar-links__subMenu {
    background: var(--color-blue-200);
    display: flex;
    flex-direction: column;
    justify-content: center;
    list-style: none;
    position: absolute;
    box-shadow: 0px 8px 16px 0px rgba(0, 0, 0, 0.2);
    padding: 13px 10px;
    width: 233px;
    gap: 5px;
    top: 28px;
    left: -34px; 
  }
  

.app__navbar-links__subMenu-smallscreen {
    background: var(--color-blue-200);
    flex-direction: column;
    justify-content: center;
    list-style: none;
    position: absolute;
    box-shadow: 0px 8px 16px 0px rgba(0, 0, 0, 0.2);
    width: 270px;
    gap: 5px;
    top: 29px;
    left: -81px;
    z-index: 1;     
}

.app__navbar-smallscreen_overlay {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100vh;
    justify-content: flex-start;
    padding-top: 150px;
    background: var(--color-blue-200);
    /* transition: 0.5s ease; */
    opacity: 1;
    flex-direction: column;
    z-index: 5;
    /* transition: all 0.5s ease; */
    -webkit-box-shadow: 3px 0px 5px 0px rgba(0, 0, 0, 0.6);
    -moz-box-shadow: 3px 0px 5px 0px rgba(0, 0, 0, 0.6);
    box-shadow: 3px 0px 5px 0px rgba(0, 0, 0, 0.6);
}

.app__navbar-smallscreen_links {
    list-style: none;
}

.app__navbar-smallscreen_links li {
    margin: 1rem;
    cursor: pointer;
    color: var(--color-golden);
    font-size: 1rem;
    text-align: center;
    font-family: var(--font-base);
    display: flex;
    align-items: center;
}
.app__navbar-smallscreen_links li a{
  display: flex;
  align-items: center;
}
.app__navbar-smallscreen_links li:hover {
    color: var(--color-white);
}

.app__navbar-smallscreen_overlay::-webkit-scrollbar {
    display: none;
}

/*For-tablet-portrait-up */
@media screen and (min-width: 750px) {
    .app__navbar-smallscreen_links li {  
        margin: 1.5rem;    
        font-size: 1.5rem;       
    }
}

/*for-desktop-up*/
@media screen and (min-width: 1150px) {
    .app__navbar {
        padding: 1rem 2rem;
    }

    .app__navbar__menu-icon {
        display: none;
    }

    .app__navbar-links {
        display: flex;
    }

    .app__navbar-smallscreen {
        display: none;
    }

    .app__navbar-smallscreen_overlay {
        display: none;
    }

    .app__navbar-logo img {
        width: 100px;
    }
}

/*for-big-desktop-up*/
@media screen and (min-width: 2000px) {
    .app__navbar {
        padding: 1rem 2rem;
    }

    .app__navbar-logo img {
        width: 180px;
    }
    .app__navbar-logo h2,
    .app__navbar-logo p{
        line-height: 50px
    }
    .app__navbar-links__subMenu {       
        width: 270px;       
        top: 48px;         
      }
}
