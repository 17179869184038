.loader-container {
    width: 100%;
    height: 100vh;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    position: fixed;
    background: url('../../assets/gradient.png');
    background-position: center;
    background-size: cover;
    background-repeat: repeat;
    background-attachment: fixed;
    z-index: 1;
    gap:20px;
}

.loader-container img{
    width: 200px;
    height: 150px;   
}
.loader-container h1{
letter-spacing: 2px;
}




