.notes {
    /* display: flex; */
    justify-content: center;   
    background-color: #f5efdbd3;
    box-shadow: inset -3px 2px 20px 4px rgba(0, 0, 0, 0.75);
    width:100%;   
    margin-bottom: 0;   
    margin-top:0;  
    padding: 15px;
    position: relative;
    top: -30px;
  }
  .notes p{
    text-align: center;
  }
 

  
  /* Extra small devices (phones, 600px and down) */
  @media only screen and (max-width: 350px) {
    .notes{
        top: -5px;
       }
  }
  
  /* Small devices (portrait tablets and large phones, 600px and up) */
  @media only screen and (min-width: 450px) {
    .notes{
      top: -10px;
     }
  }
  
  /* Medium devices (landscape tablets, 768px and up) */
  @media only screen and (min-width: 768px) {
    .notes {     
      top:-8px;   
    }
   
  }
  
  /* Large devices (laptops/desktops, 992px and up) */
  @media only screen and (min-width: 992px) {
    .notes {      
      top: -10px;
    }   
  }
  
  /* Extra large devices (large laptops and desktops, 1200px and up) */
  @media only screen and (min-width: 1200px) {
  
  }
  