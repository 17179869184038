.app__header {
    position: relative;
    display:flex;
    flex-direction: column;
    width:100%;
    
    overflow: hidden;   
    gap:20px; 
    padding-top: 105px;
    overflow: hidden;
  
}
.app__wrapper-info__title{
    display:flex;
    flex-direction: column;
    align-items: start;
}
.app__wrapper-info{
    padding-top: 0;
}

.app__header-img{   
    width: 100%;
    height: 250px;
    overflow: hidden;
    object-fit: cover;  
}
.app__wrapper-info__sign{
padding-top: 20px;
}
.app__wrapper-info__sign-img{
    width:80px;
    
}
.app__wrapper-info__sign-img img{
    width:100%;    
}
.app__header-content{
    background-color:#00000062;
    margin-top: 40px;
    padding: 20px;
    border-radius: 10px;
}
.app__header-content p{
    padding:5px;    
}
.app__header-content span{
   color: var(--color-grey);  
}

/*For-small-devices */
@media screen and (max-width: 350px) {  
    
    .app__header-img{
        height: 165px;
     }
}
/*For-tablet-portrait-up */
@media screen and (min-width: 650px) {  
    .app__header-h1 {
        font-size: 70px;
        line-height: 87px;
    }
    .app__header-img{
        height: 350px;
     }
}
/*for-desktop-up*/
@media screen and (min-width: 1150px) {   

    .app__header-img{
       height: 450px;
    }
    .app__wrapper-info__sign-img{
        width:100px;
        }
}
/*for-big-desktop-up*/
@media screen and (min-width: 1500px) {
    .app__header-h1 {
        font-size: 180px;
        line-height: 210px;
    }

    .app__header-img {
        height: 500px;
    }

    .app__wrapper-info__sign-img {
        width: 120px;
    }
}

/*for-big-desktop-up*/
@media screen and (min-width: 2000px) {
    .app__header-h1 {
        font-size: 180px;
        line-height: 210px;
    }

    .app__header-img {
        height: 850px;
    }

    .app__wrapper-info__sign-img {
        width: 120px;
    }
}




